<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col>
            <h2>
              ສິນຄ້າທັງໝົດ:
              <span class="primary-color">({{ count }})</span>
            </h2>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              clearable
              label="ຄົ້ນຫາ ດ້ວຍລະຫັດ, ຊື່"
              type="text"
              v-model="search"
              @keyup.enter="Search()"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row justify="end">
          <template>
            <v-btn class="btn-primary" fab small dark @click="OpenModalAdd()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="products"
          :disable-pagination="true"
          hide-default-footer
          class="elevation-1"
          :loading="TableLoading"
          :disabled="TableLoading"
          loading-text="Loading... Please wait"
        >
          <!--Action -->
          <template v-slot:item.image="{item}">
            <v-avatar
                size="36px"
            >
              <img v-if="item.image" :src="item.image"/>
            </v-avatar>
          </template>

          <template v-slot:item.amount="{ item }">
            <v-chip :color="getColor(item.amount)">{{ item.amount }}</v-chip>
          </template>
          <template v-slot:item.created_at="{ item }">
            {{ moment(item.created_at).format("DD-MM-YYYY") }}
          </template>
          <template v-slot:item.actions="{ item }">
            <!--
            <v-icon small class="mr-2" @click="viewInvoice(item)"
              >mdi-eye</v-icon
            >-->
            <v-icon small class="mr-2" @click="OpenModalEdit(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item.id)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
        <br />
        <Pagination
          v-if="pagination.last_page > 1"
          :pagination="pagination"
          :offset="offset"
          @paginate="fetchData()"
        ></Pagination>

        <!--Delete Modal-->
        <ModalAdd>
          <template @close="close">
            <v-card>
              <v-card-title>
                <span class="headline">Add Product</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="form" lazy-validation>
                    <v-row>
                      <v-col>
                        <div class="field">
                          <div class="file is-large is-boxed">
                            <label class="file-label">
                              <input
                                  @change="onFileChange"
                                  class="file-input input-file-image"
                                  type="file"
                                  name="image"
                                  accept="image/*"
                                  ref="image"
                                  hidden
                              />
                              <span class="file-cta">
                            <span class="file-icon">
                              <v-icon
                                  style="
                                  font-size: 60px !important;
                                  color: #719aff;
                                  cursor: pointer;
                                "
                                  class="fas fa-cloud-upload"
                              >mdi-cloud-upload</v-icon
                              >
                            </span>
                            <span
                                class="file-label"
                                style="
                                margin-top: 10px;
                                text-transform: uppercase;
                                padding-top: 20px;
                              "
                            >
                              Choose Profile
                            </span>
                          </span>
                            </label>
                          </div>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <p class="errors">
                          {{ server_errors.imageFile }}
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="mt-5" v-if="image">
                        <v-avatar class="avatar rounded" size="94px">
                          <img :src="imageUrl" alt="" />
                        </v-avatar>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          label="ລະຫັດສິນຄ້າ *"
                          required
                          v-model="product.barcode"
                          :rules="productCodeRules"
                        ></v-text-field>
                        <p class="errors">
                          {{ server_errors.barcode }}
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          label="ຊື່ສິນຄ້າ *"
                          required
                          v-model="product.name"
                          :rules="nameRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          required
                          v-model="selectedCategory"
                          :items="categories"
                          item-text="name"
                          item-value="id"
                          label="ປະເພດສິນຄ້າ *"
                          :rules="ruleCategory"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          required
                          v-model="selectedUnit"
                          :items="units"
                          item-text="unit"
                          item-value="id"
                          label="ຫົວໜ່ວຍ *"
                          :rules="ruleUnit"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                            label="ລາຄາຂາຍ"
                            v-model="product.sale_price"
                            :rules="salePriceRule"
                            type="number"
                            class="input-number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="ລາຍລະອຽດ"
                          v-model="product.description"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols>
                        <v-checkbox v-model="showProduct"
                                    label="ສະແດງລາຍການສິນຄ້າໃຫ້ລູກຄ້າເຫັນ"
                                    row>
                        </v-checkbox>
                      </v-col>

                      <v-col cols>
                        <v-checkbox v-model="feed"
                                    label="ສະແດງລາຍການສິນຄ້າໃນໜ້າຫຼັກ"
                                    row>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="CloseModalAdd()">
                    Close
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    :loading="loading"
                    :disabled="loading"
                    @click="AddItem()"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </template>
        </ModalAdd>

        <!--Edit Modal-->

        <ModalEdit>
          <template @close="close">
            <v-card>
              <v-card-title>
                <span class="headline">Update Product</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="form" lazy-validation>
                    <v-row>
                      <v-col>
                        <div class="field">
                          <div class="file is-large is-boxed">
                            <label class="file-label">
                              <input
                                  @change="onFileChange"
                                  class="file-input input-file-image"
                                  type="file"
                                  name="image"
                                  accept="image/*"
                                  ref="image"
                                  hidden
                              />
                              <span class="file-cta">
                            <span class="file-icon">
                              <v-icon
                                  style="
                                  font-size: 60px !important;
                                  color: #719aff;
                                  cursor: pointer;
                                "
                                  class="fas fa-cloud-upload"
                              >mdi-cloud-upload</v-icon
                              >
                            </span>
                            <span
                                class="file-label"
                                style="
                                margin-top: 10px;
                                text-transform: uppercase;
                                padding-top: 20px;
                              "
                            >
                              Choose Profile
                            </span>
                          </span>
                            </label>
                          </div>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <p class="errors">
                          {{ server_errors.imageFile }}
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="mt-5" v-if="image">
                        <v-avatar class="avatar rounded" size="94px">
                          <img :src="imageUrl" alt=""/>
                        </v-avatar>
                      </v-col>
                      <v-col class="mt-5" v-else>
                        <v-avatar class="avatar rounded" size="94px">
                          <img v-if="product_edit.image" :src="product_edit.image"/>
                        </v-avatar>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          label="ລະຫັດສິນຄ້າ *"
                          required
                          v-model="product_edit.barcode"
                          :rules="productCodeRules"
                        ></v-text-field>
                        <p class="errors">
                          {{ server_errors.barcode }}
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          label="ຊື່ສິນຄ້າ *"
                          required
                          v-model="product_edit.name"
                          :rules="nameRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          required
                          v-model="product_edit.categoryId"
                          :items="categories"
                          item-text="name"
                          item-value="id"
                          label="ປະເພດສິນຄ້າ *"
                          :rules="ruleCategory"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          required
                          v-model="product_edit.unitId"
                          :items="units"
                          item-text="unit"
                          item-value="id"
                          label="ຫົວໜ່ວຍ *"
                          :rules="ruleUnit"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="ລາຄາຂາຍ"
                          v-model="product_edit.sale_price"
                          type="number"
                          class="input-number"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          label="ລາຍລະອຽດ"
                          v-model="product_edit.description"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-checkbox v-model="product_edit.show"
                                    label="ສະແດງລາຍການສິນຄ້າໃຫ້ລູກຄ້າເຫັນ"
                                    row>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-checkbox v-model="product_edit.feed"
                                    label="ສະແດງລາຍການສິນຄ້າໃນໜ້າຫຼັກ"
                                    row>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="CloseModalEdit()">
                    Close
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    :loading="loading"
                    :disabled="loading"
                    @click="UpdateItem()"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </template>
        </ModalEdit>

        <!--Delete Modal-->
        <ModalDelete>
          <template>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                :loading="loadingView"
                :disabled="loadingView"
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </template>
        </ModalDelete>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import manage_warehouse from "../../../mixins/warehouse/manage_warehouse";
import Pagination from "@/plugins/pagination/pagination";

export default {
  mixins: [manage_warehouse],
  components: {
    Pagination,
  },
};
</script> 

<style scoped>
/*@import "../../../../../public/scss/main.scss";*/
.customer {
  color: #000;
  font-size: 18px;
}
.invoice {
  font-size: 18px;
}
.primary-color {
  color: #00c1d2;
}
.text-field {
  border-color: #00c1d2;
  padding: 12px 8px 12px 8px;
  width: 100%;
  margin-bottom: 12px;
  font-size: 16px;
}
</style>
