import {GetOldValueOnInput} from "@/Helpers/GetValue";

export default {
    data() {
        return {
            loading: false,
            loadingView: false,
            TableLoading: false,
            products: [],
            categories: [],
            units: [],
            selectedCategory: '',
            selectedUnit: '',
            productId: "",

            server_errors: {
                barcode: "",
                imageFile: ""
            },
            news: {},

            image: "",
            imageUrl: "",

            product: {},
            count: 0,
            product_edit: {},
            showProduct: 0,
            feed: 0,

            //Pagination
            offset: 15,
            pagination: {},
            per_page: 200,
            oldVal: "",
            search: '',
            //Location
            // Service-Zone
            checkedServiceZone: [],
            unitValue: "",
            headers: [
                {
                    text: "ລະຫັດສິນຄ້າ",
                    align: "start",
                    value: "barcode",
                    sortable: false,
                    width: "120px"
                },
                {
                    text: "Image",
                    align: "start",
                    value: "image",
                    sortable: false
                },
                {
                    text: "ຊື່ສິນຄ້າ",
                    align: "start",
                    value: "name",
                    width: "150px"
                },
                {
                    text: "ປະເພດ",
                    align: "start",
                    value: "category",
                    width: "140px"
                },
                {text: "ຈຳນວນ", value: "amount", sortable: false},
                {text: "ຫົວໜ່ວຍ", value: "unit", sortable: false,width: "140px"},
                {text: "ລາຄາຂາຍ", value: "sale_price", width: "140px"},
                {text: "ສະແດງສິນຄ້າ", value: "show", width: "150px",align: "center"},
                {text: "ສະແດງໜ້າຫຼັກ", value: "feed", width: "90px",align: "center"},
                {text: "ລາຍລະອຽດ", value: "description", width: "190px",sortable: false},
                {text: "ວັນທີ", value: "created_at", width: "120px",sortable: false},
                {text: "Actions", value: "actions", sortable: false},
            ],
            ruleCategory: [(v) => !!v || "Product Category is required"],
            ruleUnit: [(v) => !!v || "Unit is required"],
            productCodeRules: [
                (v) => !!v || "Product Code is required",
            ],
            nameRules: [
                (v) => !!v || "Name is required",
                (v) => (v && v.length >= 2) || "Must be less than 2 characters",
            ],

            salePriceRule: [
                (v) => !!v || "Price is required",
                (v) =>
                    (v && v.length >= 0) ||
                    "Price number must be numbers",
            ],
            descriptionRules: [
                (v) => !!v || "Name is required",
                (v) => (v && v.length >= 2) || "Must be less than 2 characters",
            ],
            toast: {
                value: true,
                color: "success",
                msg: "Success",
            },
            toast_error: {
                value: true,
                color: "error",
                msg: "Something when wrong!",
            },
        };
    },
    methods: {
        OpenModalAdd() {
            this.$store.commit("modalAdd_State", true);
        },

        onFileChange(e) {
            let input = e.target;
            let file = e.target.files[0];
            this.image = input.files[0];
            this.imageUrl = URL.createObjectURL(file);
            this.server_errors.imageFile = '';
            let formData = new FormData();
            formData.append("imageFile", this.image);
            this.$axios.post('/upload-image', formData)
                .then(res => {
                    if (res.status == 200) {
                        this.image = res.data.fileName;
                    }
                }).catch(error => {
                this.image = '';
                if (error.response.status == 422) {
                    const obj = error.response.data.errors;
                    for (let [key, user] of Object.entries(obj)) {
                        this.server_errors[key] = user[0];
                    }
                }
            });

        },

        AddItem() {
            if (this.$refs.form.validate() == true) {
                this.SubmitItem();
            }
        },
        SubmitItem() {
            this.product.category_id = this.selectedCategory;
            this.product.unit_id = this.selectedUnit;
            this.product.image = this.image;
            this.product.show = this.showProduct;
            this.product.feed = this.feed;
            this.loading = true;
            this.$admin
                .post("warehouse/add-product", this.product)
                .then((res) => {
                    if (res.data.success == true) {
                        setTimeout(() => {
                            this.loading = false;
                            this.CloseModalAdd();
                            this.product = {};
                            this.fetchData();
                            this.image = "";
                            this.reset();
                            this.$store.commit("Toast_State", this.toast);
                        }, 300);
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                    this.fetchData();
                    if (error.response.status == 422) {
                        const obj = error.response.data.errors;
                        for (let [key, product] of Object.entries(obj)) {
                            this.server_errors[key] = product[0];
                        }
                    }
                });
        },

        CloseModalAdd() {
            this.product = {};
            this.reset();
            this.fetchData();
            this.$store.commit("modalAdd_State", false);
        },

        OpenModalEdit(item) {
            this.product_edit = item;
            this.$store.commit("modalEdit_State", true);
        },

        UpdateItem() {
            this.product_edit.category_id = this.product_edit.categoryId;
            this.product_edit.unit_id = this.product_edit.unitId;
            this.product_edit.image = this.image;
            if (this.$refs.form.validate() == true) {
                this.loading = true;
                this.$admin
                    .post("warehouse/update-product/" + this.product_edit.id, this.product_edit)
                    .then((res) => {
                        if (res.data.success == true) {
                            setTimeout(() => {
                                this.loading = false;
                                this.CloseModalEdit();
                                this.product_edit = {};
                                this.reset();
                                this.fetchData();
                                this.image = "";
                                this.$store.commit("Toast_State", this.toast);
                            }, 300);
                        }
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.$store.commit("Toast_State", this.toast_error);
                        this.fetchData();
                        if (error.response.status == 422) {
                            const obj = error.response.data.errors;
                            for (let [key, product] of Object.entries(obj)) {
                                this.server_errors[key] = product[0];
                            }
                        }
                    });
            }
        },

        CloseModalEdit() {
            this.product_edit = {};
            this.fetchData();
            this.reset();
            this.$store.commit("modalEdit_State", false);
        },

        fetchData() {
            this.TableLoading = true;
            this.$admin.get('warehouse/products', {
                params: {
                    page: this.pagination.current_page,
                    per_page: this.per_page,
                    search: this.search
                }
            }).then(res => {
                setTimeout(() => {
                    this.products = res.data.products.data;
                    this.pagination = res.data.products;
                    this.count = res.data.products.total
                    this.TableLoading = false;
                }, 500);
            }).catch(() => {
                this.TableLoading = false;
                this.$store.commit("Toast_State", this.toast_error);
            });

            this.$admin
                .get("list-categories")
                .then((res) => {
                    this.categories = res.data.categories;
                    this.TableLoading = false;
                })
                .catch(() => {
                    this.TableLoading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                });

            this.$admin
                .get("warehouse/unit")
                .then((res) => {
                    setTimeout(() => {
                        this.units = res.data.units;
                        this.TableLoading = false;
                    }, 100);
                })
                .catch(() => {
                    this.TableLoading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                });
        },

        closeDelete() {
            this.$store.commit("modalDelete_State", false);
        },

        deleteItem(id) {
            this.productId = id;
            this.$store.commit("modalDelete_State", true);
        },

        deleteItemConfirm() {
            this.loading = true;
            this.$admin
                .delete("warehouse/delete-product/" + this.productId)
                .then((res) => {
                    if (res.data.success == true) {
                        setTimeout(() => {
                            this.fetchData();
                            this.$store.commit("modalDelete_State", false);
                            this.loading = false;
                            this.$store.commit("Toast_State", this.toast);
                        }, 300);
                    }
                })
                .catch(() => {
                    this.$store.commit("Toast_State", this.toast_error);
                    this.fetchData();
                    this.$store.commit("modalDelete_State", false);
                    this.loading = false;
                });
        },
        reset() {
            this.$refs.form.reset();
        },
        closeModalView() {
            this.loadingView = false;
            this.$store.commit("modalView_State", false);
        },

        ///Search
        Search() {
            GetOldValueOnInput(this);
        },

        //Get Round color
        getColor(amount) {
            if (amount == 0) return "error";
            else return "success";
        },
    },
    watch: {
        "product.barcode": function () {
            this.server_errors.barcode = "";
        },
        "product_edit.barcode": function () {
            this.server_errors.barcode = "";
        },
        'search': function (value) {
            if (value == '') {
                this.fetchData();
            }
        },
        'showProduct': function (value) {
            if (value == true) {
                return 1;
            } else if (value == false) {
                return 0;
            }
        },
        feed: function (value) {
            if (value == true) {
                return 1;
            } else if (value == false) {
                return 0;
            }
        },
        'product_edit.show': function (value) {
            if (value == true) {
                return 1;
            } else if (value == false) {
                return 0;
            }
        },
        'product_edit.feed': function (value) {
            if (value == true) {
                return 1;
            } else if (value == false) {
                return 0;
            }
        }
    },

    created() {
        this.fetchData();
    },
};
